import React, { useContext, useState, useEffect } from "react";
import  {Redirect} from 'react-router-dom'
import {MyContext} from "../contexts/MyContext";
import Login from "./Login";
import Button from '@mui/material/Button';
import {Link} from "react-router-dom";
import parse from 'html-react-parser';
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Filedialog from './Filedialog';
import DialogAufbau from "./DialogAufbau";
import DialogAbbau from "./DialogAbbau";
import DialogService from "./DialogService";
import DialogSonstiges from "./DialogSonstiges";
import DialogBemerkung from "./DialogBemerkung";
import db from './db'



function Tourdaten(){
    const {rootState} = useContext(MyContext);
    const {isAuth,showLogin,theUser} = rootState;


    const urlParams = new URLSearchParams(window.location.search);
    const tourid = urlParams.get('tourid');
    const [auftragsdaten, setAuftragsdaten] = useState([]);
    const [defaultDatum, setdefaultDatum] = useState("");
    const [showDatumClass, setshowDatumClass] = useState("");
    const [bemerkungen, setBemerkungen] = useState("");
    const [bemerkungNeu, setBemerkungNeu] = useState("");
    //const [bauschildnummer, setbauschildnummer] = useState("");
    const [designURL, setdesignURL] = useState("");
    const [nodesign, setnodesign] = useState("");

    const [design, setDesign] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [modalLat, setModalLat] = useState('');
    const [modalLng, setModalLng] = useState('');
    const [datumClass, setdatumClass] = useState('');
    const [uebetragenClass, setuebetragenClass] = useState('hide');
    const [ButtonLabel, setButtonLabel] = useState('');
    const [open, setOpen] = React.useState(false);
    const [mappendaten, setMappendaten] = useState('');
    const [dokumentdaten, setDokumentdaten] = useState('');

    // Bilder vorhanden
    const [Bild1Vorhanden, setBild1Vorhanden] = useState(false);
    const [Bild2Vorhanden, setBild2Vorhanden] = useState(true);
    const [Bild3Vorhanden, setBild3Vorhanden] = useState(true);
    const [Bild4Vorhanden, setBild4Vorhanden] = useState(true);

    const [bild1, setBild1] = useState();
    const [bild2, setBild2] = useState();
    const [bild3, setBild3] = useState();
    const [bild4, setBild4] = useState();

    const [bildladenFeedback, setBildladenFeedback] = useState("");


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (url) => {
        window.open(url, '_blank'); // Öffnet die URL in einem neuen Tab
    };

    // Token aus LocalStorage holen
    const loginToken = localStorage.getItem('loginToken');
    const id = urlParams.get('id');
    //const URL = "https://api.bauschild-portal.de/restful-api-webapp/tourdaten.php?id="+id+"&t="+loginToken;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getSchilddatenById();
        getBilder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSchilddatenById = async () => {
        var id = parseInt(urlParams.get('id'));
        console.log("ID:"+id);
        try {
            const data = await db.schilder.where('id').equals(id).first();
            if (data===undefined){
                console.log("error");
            } else {
                console.log("noerror");
                setAuftragsdaten(data);
                if (data.length===0)
                    return <Redirect to='/'  />
                switch(data.Typ) {
                    case "A":
                        if (data.aufbaudatum!==""){
                            setdatumClass("datumFieldHide");
                            setdefaultDatum(data.aufbaudatum);
                        }
                        setButtonLabel("Aufbau - Auftrag abschließen");
                        break;
                    case "W":
                        if (data.fm==="auftragabgeschlossen" && data.istuebertragen===1){
                            setdatumClass("datumFieldHide");
                        }
                        if (data.mietende !== "") {
                            setdefaultDatum(data.mietende);
                        }
                        setButtonLabel("Abbau - Auftrag abschließen");
                        break;
                    case "S":
                        if (data.fm==="auftragabgeschlossen" && data.istuebertragen===1) {
                            //setdatumClass("datumFieldHide");
                        } else if (data.fm==="auftragabgeschlossen" && data.istuebertragen===""){
                            setdatumClass("datumFieldHide");
                        } else {
                            setButtonLabel("Service - Auftrag bearbeiten/abschließen");
                            setshowDatumClass("hide");
                        }
                        break;
                    case "X":
                        if (data.fm==="auftragabgeschlossen" && data.istuebertragen===1){
                            //setdatumClass("datumFieldHide");
                        } else if (data.fm==="auftragabgeschlossen" && data.istuebertragen===""){
                            setdatumClass("datumFieldHide");
                        } else {
                            setButtonLabel("Sonstiges - Auftrag bearbeiten/abschließen");
                            setshowDatumClass("hide");
                        }
                        break;
                    default:
                        setdatumClass("datumFieldHide");
                        setButtonLabel("Auftrag abschließen");
                        break;
                }
                if (data.fm === "auftragabgeschlossen" && data.istuebertragen==="") setuebetragenClass("");
                if (data.fm === "auftragnichtabgeschlossen" && data.istuebertragen==="") setuebetragenClass("hide");

                // Bemerkungen aus DB-Tabelle vermietungen #############################################################
                var bauschild_bemerkungen = data.bauschild_bemerkungen;
                if (bauschild_bemerkungen!=null) {
                    bauschild_bemerkungen = bauschild_bemerkungen.replace("<red>", "<span class='rot'>", bauschild_bemerkungen);
                    bauschild_bemerkungen = bauschild_bemerkungen.replace("</red>", "</span>", bauschild_bemerkungen);
                    bauschild_bemerkungen = bauschild_bemerkungen.replace("\n", "<br />", bauschild_bemerkungen);
                    bauschild_bemerkungen += "<br />";
                }
                // Bemerkungen aus DB-Tabelle vermietungen_bemerkungen #################################################
                const databemerkungen = await db.bemerkungen.where('vid').equals(data._ID_Vermietung).toArray();
                databemerkungen.forEach((bemerkung) => {
                    // Verarbeite jeden Datensatz hier
                    bauschild_bemerkungen += "<br />"+bemerkung.user+": "+bemerkung.bemerkung;
                });

                setBemerkungen(bauschild_bemerkungen);

                if (data.design==="") setnodesign("Design noch nicht geladen.");

                setdesignURL("https://api.bauschild-portal.de/restful-api-webapp/getDesign.php?id="+id+"&t="+loginToken+"&bauschildnummer="+data.bauschildnummer);

                // Modal - Übergabe der Daten
                var latlong = data.latlong.split(",");
                setModalLat(latlong[0]);
                setModalLng(latlong[1]);
                setModalTitle(data.latlong);
                setShowModal(false);

                // Designbild ##########################################################################################
                if (data.design===""){
                    setDesign('');
                    // Design laden, sofern Internetverbindung vorhanden ... Abfrage Internetverbindung noch integrieren
                    //const designbild = designladen(data.bauschildnummer);
                } else {
                    setDesign(<img src={data.design} alt="Design" />)
                }

                // Mappe ###############################################################################################
                var mappe = JSON.parse(data.mappe);
                const listItems = Object.keys(mappe).map((key) => (
                    mappe[key] !== "" && (
                        <Button
                            className="listbutton"
                            variant="outlined"
                            onClick={() => handleClick(mappe[key])}
                            key={key}
                        >
                            {key}
                        </Button>
                    )
                ));
                setMappendaten(listItems);

                // Dokumente ###############################################################################################
                var dokumente = JSON.parse(data.dokumente);
                var zaehler = 0;
                const listItemsDokument = Object.keys(dokumente).map((key) => (
                    <Button
                        className="listbutton"
                        variant="outlined"
                        onClick={() => handleClick(dokumente[key].pfad)}
                        key={key}
                    >
                        {`Datei ${++zaehler}`}
                    </Button>
                ));
                setDokumentdaten(listItemsDokument);
            }
        } catch (error) {

        }
    };

    const getBilder = async () => {
        var vid = parseInt(urlParams.get('vid'));
        try {
            const data = await db.bilder.where('vid').equals(vid).first();

            if (data.bild1==="" || data.bild1===null){setBild1('')} else {setBild1(<img src={data.bild1} alt="Baustellenfoto" />);setBild1Vorhanden(true);}
            if (data.bild2==="" || data.bild2===null){setBild2('')} else {setBild2(<img src={data.bild2} alt="Baustellenfoto" />);setBild2Vorhanden(true);}
            if (data.bild3==="" || data.bild3===null){setBild3('')} else {setBild3(<img src={data.bild3} alt="Baustellenfoto" />);setBild3Vorhanden(true);}
            if (data.bild4==="" || data.bild4===null){setBild4('')} else {setBild4(<img src={data.bild4} alt="Baustellenfoto" />);setBild4Vorhanden(true);}
            /*
            if (data.bild1==="" || data.bild1===null){setBild1('')} else {setBild1Vorhanden(true);}
            if (data.bild2==="" || data.bild2===null){setBild2('')} else {setBild2Vorhanden(true);}
            if (data.bild3==="" || data.bild3===null){setBild3('')} else {setBild3Vorhanden(true);}
            if (data.bild4==="" || data.bild4===null){setBild4('')} else {setBild4Vorhanden(true);}

             */
        } catch (error) {

        }
    }

    const designladen = async () => {
        const responsedesign = await fetch(designURL);
        const jsonDesigndata = await responsedesign.json();
        if (jsonDesigndata.design!==""){
            setDesign(<img src={jsonDesigndata.design} alt="Design" />);
            setnodesign("");
            // In DB speichern
            try {
                await db.schilder.update(parseInt(id), { design: jsonDesigndata.design });
            } catch (error) {
                console.error(error);
            }
        } else {
            setnodesign("Es konnte kein Design gefunden werden.");
        }
    }

    const handleChange = (event) => {
        setBemerkungNeu(event.target.value);
    };

    const updateBilder = (bildnummer,imageBase64,vid) => {
        console.log(bildnummer,vid);
        try {
            db.bilder.where('vid').equals(parseInt(vid)).modify((record) => {
                // Aktualisiere den Datensatz hier
                console.log(record);
                if (bildnummer==="1") record.bild1 = imageBase64;
                if (bildnummer==="2") record.bild2 = imageBase64;
                if (bildnummer==="3") record.bild3 = imageBase64;
                if (bildnummer==="4") record.bild4 = imageBase64;
            });
            console.log("Datensätze wurden aktualisiert.");
        } catch (error) {
            console.error(error);
        }

    }

    const ShowBild1 = async (event) => {
        var vid = parseInt(urlParams.get('vid'));
        var data = await db.bilder.where('vid').equals(parseInt(vid)).first();

        console.log(data);

        var bildergeladen1 = false;
        var bildergeladen2 = false;
        var bildergeladen3 = false;
        var bildergeladen4 = false;

        if (data.bild1!=""){setBild1(<img src={data.bild1} alt="Foto 1" />);bildergeladen1=true;}
        if (data.bild2!=""){setBild2(<img src={data.bild2} alt="Foto 2" />);bildergeladen2=true;}
        if (data.bild3!=""){setBild3(<img src={data.bild3} alt="Foto 3" />);bildergeladen3=true;}
        if (data.bild4!=""){setBild4(<img src={data.bild4} alt="Foto 4" />);bildergeladen4=true;}



        if (!bildergeladen1 || !bildergeladen2 || !bildergeladen3 || !bildergeladen4) {
            var fotoUrl = "https://api.bauschild-portal.de/restful-api-webapp/getFoto.php?id=" + id + "&t=" + loginToken + "&vid=" + vid + "&bauschildnummer=" + auftragsdaten.bauschildnummer;
            setBildladenFeedback("Bilder werden geladen, bitte warten ...");
            const responsefoto = await fetch(fotoUrl);
            const jsonFoto = await responsefoto.json();

            if (jsonFoto.bild1 !== "") {
                setBild1(<img src={jsonFoto.bild1} alt="Foto 1"/>);
                setBild1Vorhanden(true);
                bildergeladen1=true;
                setBildladenFeedback("");
                await updateBilder("1",jsonFoto.bild1,vid);
            }
            if (jsonFoto.bild2 !== "") {
                setBild2(<img src={jsonFoto.bild2} alt="Foto 2"/>);
                setBild2Vorhanden(true);
                bildergeladen2=true;
                setBildladenFeedback("");
                await updateBilder("2",jsonFoto.bild2,vid);
            }
            if (jsonFoto.bild3 !== "") {
                setBild3(<img src={jsonFoto.bild3} alt="Foto 3"/>);
                setBild3Vorhanden(true);
                bildergeladen3=true;
                setBildladenFeedback("");
                await updateBilder("3",jsonFoto.bild3,vid);
            }
            if (jsonFoto.bild4 !== "" & data.bild4.includes("data:image/jpeg;base64")) {
                setBild4(<img src={jsonFoto.bild4} alt="Foto 4"/>);
                setBild4Vorhanden(true);
                bildergeladen4=true;
                setBildladenFeedback("");
                await updateBilder("4",jsonFoto.bild4,vid);
            }
        }

        if (!bildergeladen1 && !bildergeladen2 && !bildergeladen3 && !bildergeladen4)
        setBildladenFeedback("Es konnten keine Fotos gefunden werden.");


    };

    const saveBemerkung = async () => {
        var vid = parseInt(urlParams.get('vid'));

        document.getElementById("bemerkung").value = "";
        db.bemerkungen.add({tourid, vid:vid, uid:theUser.id, user:theUser.anzeigename, bemerkung:bemerkungNeu, uebertragen:"", auftragsart:auftragsdaten.Typ});

        updateSchilddaten(vid, { istuebertragen:"" });
        getBemerkungen(vid);
    }

    const updateSchilddaten = async (vid, newData) => {
        try {
            await db.schilder.where('_ID_Vermietung').equals(vid).modify((record) => {
                // Aktualisiere den Datensatz hier
                record.istuebertragen = newData.istuebertragen;
                if (auftragsdaten.fm==="auftragabgeschlossen") setuebetragenClass("");
                console.log("Datensatz wurde aktualisiert");
            });
        } catch (error) {
            console.error(error);
        }
    };
    const getBemerkungen = async (vid) => {
        var bauschild_bemerkungen = auftragsdaten.bauschild_bemerkungen;
        try {
            const databemerkungen = await db.bemerkungen.where('vid').equals(vid).toArray();
            databemerkungen.forEach((bemerkung) => {
                bauschild_bemerkungen += "<br />"+bemerkung.user+": "+bemerkung.bemerkung;
            });
            setBemerkungen(bauschild_bemerkungen);
        } catch (error) {
            console.error(error);
        }
    };


    const transferDaten = async () => {
        var vid = parseInt(urlParams.get('vid'));
        try {
            var data = await db.schilder.where('id').equals(parseInt(id)).first();
            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferDaten.php", {
                tourid:tourid,
                token:loginToken,
                vid:vid,
                Gewicht_Aufbau:data.Gewicht_Aufbau,
                Gewicht_Abbau:data.Gewicht_Abbau,
                aufbaudatum:data.aufbaudatum,
                abbaudatum:data.abbaudatum,
                bauschildnummer:data.bauschildnummer,
                auftragsart:auftragsdaten.Typ,
                userid:theUser.id,
                test:0
            })
                .then((response) => {
                    console.log(response.data);
                    console.log("Daten übertragen");
                    transferBilder();
                    //transferBemerkungen();
                });
        } catch (error) {
            console.error(error);
        }
    }

    const transferDatenAbschliessen = async () => {
        var vid = parseInt(urlParams.get('vid'));
        try {
            var data = await db.schilder.where('id').equals(parseInt(id)).first();
            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferDaten.php", {
                tourid:tourid,
                token:loginToken,
                vid:vid,
                Gewicht_Aufbau:data.Gewicht_Aufbau,
                Gewicht_Abbau:data.Gewicht_Abbau,
                aufbaudatum:data.aufbaudatum,
                abbaudatum:data.abbaudatum,
                bauschildnummer:data.bauschildnummer,
                auftragsart:auftragsdaten.Typ,
                userid:theUser.id,
                test:0
            })
                .then((response) => {
                    db.schilder.update(parseInt(id), { fm:'auftragabgeschlossen', istuebertragen: 1 });
                    setuebetragenClass('hide');
                    handleClose();
                });
        } catch (error) {
            console.error(error);
        }
    }


    const transferBilder = async () => {
        var vid = parseInt(urlParams.get('vid'));

        try {
            var data = await db.bilder.where('vid').equals(parseInt(vid)).first();

            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferBilder.php", {
                tourid:tourid,
                token:loginToken,
                vid:vid,
                bild1:data.bild1,
                bild2:data.bild2,
                bild3:data.bild3,
                bild4:data.bild4,
                bauschildnummer:auftragsdaten.bauschildnummer,
            })
                .then((response) => {
                    console.log(response.data);
                    console.log("Bilder übertragen");
                });
        } catch (error) {
            console.error(error);
        }

    }

    const transferBemerkungen = async () => {
        var vid = parseInt(urlParams.get('vid'));
        try {
            var databemerkungen = await db.bemerkungen.where('vid').equals(parseInt(vid)).toArray();

            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferBemerkungen.php", {
                tourid:tourid,
                token:loginToken,
                vid:vid,
                userid:theUser.id,
                bauschildnummer:auftragsdaten.bauschildnummer,
                databemerkungen:JSON.stringify(databemerkungen)
            })
                .then((response) => {
                    console.log("Bemerkungen übertragen");
                    console.log(response.data);
                    db.schilder.update(parseInt(id), { fm:'auftragabgeschlossen', istuebertragen: 1 });
                    setuebetragenClass('hide');
                    handleClose();
                });
        } catch (error) {
            console.error(error);
        }

    }


    function handleAuftragChange(value){
        if (value){
            setdatumClass("datumFieldHide");
            setuebetragenClass('');
        }
    }


    if(isAuth) {
        var auftragsart     = "";

        switch(auftragsdaten.Typ) {
            case "A":
                auftragsart = "Aufbau";
                break;
            case "W":
                auftragsart = "Abbau";
                break;
            case "S":
                auftragsart = "Service";
                break;
            default:
                break;
        }
        return (
            <div className="content">
                <Button variant="contained" className="backbutton"><Link to={`/tour/?id=${tourid}`}>Zurück zur Tour</Link></Button>

                <div className="gridtop">
                    <h2>Akte: {auftragsdaten.bauschildnummer}</h2>
                    <div className={datumClass}>
                        {(() => {
                            if (auftragsdaten.Typ==="A") {
                                return (<DialogAufbau vid={id} bildvorhanden={Bild1Vorhanden} IDVermietung={auftragsdaten._ID_Vermietung} tourid={tourid} onValueChange={handleAuftragChange} setBemerkungen={setBemerkungen} transferBilder={transferBilder} id={urlParams.get('id')}  />)
                            } else if (auftragsdaten.Typ==="W") {
                                return (<DialogAbbau vid={id} bildvorhanden={Bild1Vorhanden} IDVermietung={auftragsdaten._ID_Vermietung} tourid={tourid} onValueChange={handleAuftragChange} setuebetragenClass={setuebetragenClass} setBemerkungen={setBemerkungen} transferBilder={transferBilder} id={urlParams.get('id')} />)
                            } else if (auftragsdaten.Typ==="S") {
                                return (<DialogService vid={id} bildvorhanden={Bild1Vorhanden} IDVermietung={auftragsdaten._ID_Vermietung} tourid={tourid} onValueChange={handleAuftragChange} transferDaten={transferDaten} setBemerkungen={setBemerkungen} transferBilder={transferBilder} id={urlParams.get('id')}/>)
                            } else if (auftragsdaten.Typ==="X" & auftragsdaten.fm !== "auftragabgeschlossen") {
                                return (<DialogSonstiges vid={id} bildvorhanden={Bild1Vorhanden} IDVermietung={auftragsdaten._ID_Vermietung} tourid={tourid} onValueChange={handleAuftragChange} transferDaten={transferDaten} setBemerkungen={setBemerkungen} transferBilder={transferBilder}  id={urlParams.get('id')} />)
                            }
                        })()}
                    </div>

                    <div className={uebetragenClass}>
                        <Button style={{
                            backgroundColor: "#358d51",
                        }} variant="contained" onClick={handleClickOpen}>Auftragsdaten jetzt übermitteln</Button>
                    </div>

                </div>
                <div className="grid2">
                    <div className="grid2_div_mb mr">
                        <div className="grid2_child">
                            <div className="divtitel">Kunde:</div>
                            <div>{auftragsdaten.kunde}</div>
                        </div>
                        <div className="grid2_child">
                            <div className="divtitel">Adresse:</div>
                            <div>{auftragsdaten.bauvorhaben}</div>
                        </div>
                        <div className="grid2_child">
                            <div className="divtitel">Bauvorhaben:</div>
                            <div>{auftragsdaten.bauvorhaben_name}</div>
                        </div>
                        <div className="grid2_child">
                            <div className="divtitel">What3Words</div>
                            <a href={`https://what3words.com/${auftragsdaten.what3words}`}  rel="noopener noreferrer" target='_blank'>{auftragsdaten.what3words}</a>
                        </div>
                        <div className="grid2_child mb">
                            <div className="divtitel">Google Map</div>
                            <a href={`https://google.com/maps/place/${modalLat},${modalLng}`}  rel="noopener noreferrer" target='_blank'>Google-Map öffnen</a>
                        </div>
                    </div>
                    <div className="grid2_div_mb">
                        <div className="grid2_child">
                            <div className="divtitel">Schildtyp:</div>
                            <div>{auftragsdaten.schildnr}</div>
                        </div>
                        <div className="grid2_child">
                            <div className="divtitel">Auftragsart:</div>
                            <div>{auftragsart}</div>
                        </div>

                            <div className="grid2_child">
                                <div className="divtitel">Gewichte:</div>
                                <div>
                                    {auftragsdaten?.Gewicht_Aufbau !== "" && (
                                        <span>Aufbau: {auftragsdaten.Gewicht_Aufbau} </span>
                                    )}
                                    {auftragsdaten?.Gewicht_Abbau !== "" && (
                                        <span>Abbau: {auftragsdaten.Gewicht_Abbau}</span>
                                    )}
                                </div>
                            </div>

                        <div className={`grid2_child ${showDatumClass}`}>
                                <div className="divtitel">Datum:</div>
                                <div>
                                    <div>{defaultDatum}</div>
                                </div>
                                <div></div>
                        </div>

                        <div className="grid2_child">
                            <div className="divtitel">Mappe:</div>
                            <div>
                                <div>

                                        {mappendaten}

                                </div>
                            </div>
                            <div></div>
                        </div>



                        <div className="grid2_child">
                            <div className="divtitel">Kundendokumente:</div>
                            <div>
                                <div>
                                    <ul className="liste nolistitem">
                                        {dokumentdaten}
                                    </ul>
                                </div>
                            </div>
                            <div></div>
                        </div>


                    </div>
                </div>
                <div className="grid2 ">
                    <div className="grid2_div_mb mr">
                        <div className="child">
                            <div className="divtitel mb">Bemerkungen:</div>
                            <div className="mb">{parse(bemerkungen)}</div>
                            <div>
                                <DialogBemerkung vid={id} IDVermietung={auftragsdaten._ID_Vermietung} tourid={tourid} setBemerkungen={setBemerkungen} auftragsart={auftragsdaten.Typ} />
                            </div>
                        </div>
                    </div>
                    <div className="grid2_div_mb">
                        <div className="image design">
                            {design}
                            {design==="" ? <Button variant="contained" onClick={designladen}>Design laden</Button> : <p></p>}
                            <p>{nodesign}</p>

                        </div>


                    </div>

                </div>

                <div className="grid2">
                    <div className="grid2_div_mb mr">
                        <div className="image">
                            <div className="buttongrid">
                                <Filedialog bildnummer="1" tourid={tourid} vid={auftragsdaten._ID_Vermietung} onBild1Change={setBild1Vorhanden} onShowBild1={setBild1} />
                                <Button variant="contained" onClick={ShowBild1}>Bild(er) anzeigen</Button>
                            </div>
                            {bildladenFeedback}
                            {bild1}
                            {Bild1Vorhanden ? <p></p> : <p>Bild noch nicht vorhanden / noch nicht geladen</p>}
                        </div>
                    </div>
                    <div className="grid2_div_mb">
                        <div className="image">
                            <div className="buttongrid">
                                <Filedialog bildnummer="2" tourid={tourid} vid={auftragsdaten._ID_Vermietung} onBild2Change={setBild2Vorhanden} onShowBild2={setBild2} />
                            </div>
                            {bild2}
                            {Bild2Vorhanden ? <p></p> : <p>Bild noch nicht vorhanden</p>}
                        </div>
                    </div>
                </div>
                <div className="grid2">
                    <div className="grid2_div_mb mr">
                        <div className="image">
                            <div className="buttongrid">
                                <Filedialog bildnummer="3" tourid={tourid} vid={auftragsdaten._ID_Vermietung} onBild3Change={setBild3Vorhanden} onShowBild3={setBild3} />
                            </div>
                            {bild3}
                            {Bild3Vorhanden ? <p></p> : <p>Bild noch nicht vorhanden</p>}
                        </div>
                    </div>
                    <div className="grid2_div_mb">
                        <div className="image">
                            <div className="buttongrid">
                                <Filedialog bildnummer="4" tourid={tourid} vid={auftragsdaten._ID_Vermietung} onBild4Change={setBild4Vorhanden} onShowBild4={setBild4} />
                            </div>
                            {bild4}
                            {Bild4Vorhanden ? <p></p> : <p>Bild noch nicht vorhanden</p>}
                        </div>
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                       Auftragsdaten übermitteln
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p>Sollen die Auftragsdaten jetzt übermittelt werden?</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={transferDatenAbschliessen}>Ja, übermitteln</Button>
                        <Button onClick={handleClose} autoFocus>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
    else if(showLogin){
        return <Login/>;
    }
}

export default Tourdaten;
