import React, {useContext, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import db from './db'
import {MyContext} from "../contexts/MyContext";
import axios from "axios";
//import axios from "axios";



function DialogBemerkung(props) {

    const {rootState} = useContext(MyContext);
    const {theUser} = rootState;

    const [bemerkung, setBemerkung] = useState("");

    const [openFile, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0
        const year = today.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;

        setOpen(false);
        if (!props.bildvorhanden){
            seterrorOpen(true);
        } else {
            seterrorOpen(false);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const setBemerkungNeu = (event) => {
        if (event.target.value !== "") {
            setBemerkung(event.target.value);
        }
    }

    const [open, setOpen] = useState(false);
    const [errorOpen, seterrorOpen] = useState(false);

    const transferBemerkungen = async () => {
        var vid = parseInt(props.IDVermietung);
        await db.bemerkungen.add({tourid:props.tourid, vid:props.IDVermietung, uid:theUser.id, user:theUser.anzeigename, bemerkung:bemerkung, uebertragen:"", auftragsart:"W"});

        if (bemerkung !== "") {
            var databemerkungen = await db.bemerkungen.where('vid').equals(parseInt(vid)).toArray();
            console.log(databemerkungen);
            var bauschild_bemerkungen = "";
            databemerkungen.forEach((bemerkung) => {
                bauschild_bemerkungen += "<br />"+bemerkung.user+": "+bemerkung.bemerkung;
            });
            props.setBemerkungen(bauschild_bemerkungen);

            try {
                axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferBemerkungen.php", {
                    tourid: props.tourid,
                    vid: vid,
                    userid: theUser.id,
                    databemerkung: bemerkung,
                    auftragsart: props.auftragsart
                })
                    .then((response) => {
                        console.log("Bemerkungen übertragen");
                        console.log(response.data);
                    });
            } catch (error) {
                console.error(error);
            }
            setDialogOpen(false);
        }
    }


    function handleAuftragChange() {
        props.onValueChange(true);
    }

    return (
        <>
            <Dialog
                open={openFile}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Bemerkung hinzufügen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <div>

                            <div className="mb">
                                <div>
                                    <TextField
                                        id="bemerkung"
                                        label=""
                                        multiline
                                        rows={4}
                                        inputProps={{
                                            cols: 100
                                        }}
                                        defaultValue=""
                                        onChange={setBemerkungNeu}
                                    />
                                </div>
                            </div>


                            <div className="mb">
                                <Button variant="contained" onClick={transferBemerkungen}>Bemerkung speichern</Button>
                            </div>

                            <Collapse in={open}>
                                <Alert

                                >
                                    Bemerkung erfolgreich gespeichert!
                                </Alert>
                            </Collapse>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Fenster schließen</Button>

                </DialogActions>
            </Dialog>
            <Button variant="contained" onClick={handleClickDialogOpen}>Bemerkung eingeben</Button>
        </>
    );

}

export default DialogBemerkung;
