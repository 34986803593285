import React, {useContext, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import db from './db'
import {MyContext} from "../contexts/MyContext";
import axios from "axios";
//import axios from "axios";



function DialogService(props) {
    //const [step1, setStep1Saved] = useState("");
    const [step1Class, setStep1Class] = useState("");
    const [step2Class, setStep2Class] = useState("hide");


    const [abbauDatum, setabbauDatum] = useState('');
    const [bemerkung, setBemerkung] = useState("");



    const {rootState} = useContext(MyContext);
    const {theUser} = rootState;

    const [openFile, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0
        const year = today.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        setabbauDatum(formattedDate);

        setOpen(false);
        if (!props.bildvorhanden){
            seterrorOpen(true);
        } else {
            seterrorOpen(false);
        }
    };


    const handleDialogClose = () => {
        //setStep1Saved("");
        setStep1Class("");
        setStep2Class("hide");
        setDialogOpen(false);
    };

    function saveStep1True() {
        //setStep1Saved("1");
        setStep1Class("hide");
        setStep2Class("");
    }


    const [open, setOpen] = useState(false);
    const [errorOpen, seterrorOpen] = useState(false);

    const updateSchilddaten = async (id, newData) => {
        try {
            await db.schilder.update(id, newData);
            console.log("Datensatz wurde aktualisiert.");
        } catch (error) {
            console.error(error);
        }
    };

    const setBemerkungService = (event) => {
        if (event.target.value !== "") {
            setBemerkung(event.target.value);
        }
    }

    const auftragAbschliessen = async () => {
        setOpen(true);
        //handleAuftragChange();
        updateSchilddaten(parseInt(props.vid), { fm: "auftragabgeschlossen", istuebertragen: 1 });
        db.bemerkungen.add({tourid:props.tourid, vid:props.IDVermietung, uid:theUser.id, user:theUser.anzeigename, bemerkung:bemerkung, uebertragen:"", auftragsart:"S"});
        await transferBemerkungen(bemerkung);

        const data = await db.schilder.where('id').equals(parseInt(props.id)).first();
        var bauschild_bemerkungen = data.bauschild_bemerkungen + "<br />";

        const databemerkungen = await db.bemerkungen.where('vid').equals(props.IDVermietung).toArray();
        databemerkungen.forEach((bemerkung) => {
            // Verarbeite jeden Datensatz hier
            bauschild_bemerkungen += "<br />"+bemerkung.user+": "+bemerkung.bemerkung;
        });

        props.setBemerkungen(bauschild_bemerkungen);
        props.transferDaten();
    }


    const transferBemerkungen = async (bemerkung) => {
        var vid = parseInt(props.IDVermietung);
        try {
            var databemerkungen = await db.bemerkungen.where('vid').equals(parseInt(vid)).toArray();
            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferBemerkungen.php", {
                tourid:props.tourid,
                vid:vid,
                userid:theUser.id,
                databemerkung:bemerkung,
                auftragsart:"S"
            })
                .then((response) => {
                    console.log("Bemerkungen übertragen");
                    console.log(response.data);
                });
        } catch (error) {
            console.error(error);
        }
    }


    function handleAuftragChange() {
        props.onValueChange(true);
    }

    return (
        <>
            <Dialog
                open={openFile}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Service - Auftrag abschließen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div id="step1" className={step1Class}>
                            <div className="griddialog">
                                <div>Soll der Serviceauftrag abgeschlossen werden?</div>
                                <div>
                                    <Button variant="outlined" size="small" onClick={saveStep1True}>Ja</Button>
                                </div>
                            </div>
                        </div>
                        <div id="step2" className={step2Class}>
                            <div className="griddialog mb">
                                <div>Datum Service</div>
                                <TextField
                                    id="abbauDatum"
                                    disabled
                                    variant="outlined"
                                    defaultValue={abbauDatum}
                                    size="small"
                                />
                            </div>

                            <div className="mb">
                                <div className="mb">Bitte gib ein, welcher Servcie durchgeführt wurde.</div>
                                <div>
                                    <TextField
                                        id="bemerkung"
                                        label="Deine Bemerkung"
                                        multiline
                                        rows={4}
                                        inputProps={{
                                            cols: 100
                                        }}
                                        defaultValue=""
                                        onChange={setBemerkungService}
                                    />
                                </div>
                            </div>

                            <div className="mb">
                                <Button variant="contained" onClick={auftragAbschliessen}>Auftrag jetzt abschließen</Button>
                            </div>

                            <Collapse in={open}>
                                <Alert

                                >
                                    Auftrag erfolgreich abgeschlossen und übermittelt!
                                </Alert>
                            </Collapse>

                            <Collapse in={errorOpen}>
                                <Alert severity="error">
                                    Du hast noch kein Foto hochgeladen oder es wurde noch nicht übertragen!
                                </Alert>
                            </Collapse>


                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Schließen</Button>

                </DialogActions>
            </Dialog>
            <Button variant="contained" onClick={handleClickDialogOpen}>Service - Auftrag bearbeiten / abschließen</Button>
        </>
    );

}

export default DialogService;
